import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaChevronRight } from "react-icons/fa"

const BrandsFilter = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allBrandsYaml {
        edges {
          node {
            id
            description
          }
        }
      }
      allSubbrandsYaml {
        edges {
          node {
            brand_id
            subbrand_id
            description
            types {
              type_id 
              sizes {
                size_id
                min
                max
                unit
                num	
              }
            }
          }
        }
      }
    }
  `)
  // 指定のタイプ×ブランドがサブブランドに含まれているブランドを抽出
  const filterBrandsYaml = data.allBrandsYaml.edges.filter(edge => {
    let target_brand_id = edge.node.id
    return includesSubbrand(props.types, target_brand_id, data)
  })

  return (
      <>
        <h2 className="title is-5" id="brand" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>シリーズから探す</h2>
        <div className="columns is-multiline is-gapless">
          {filterBrandsYaml.map(edge => {
            const brand = edge.node
            let most_min = 100
            let most_max = 0
            data.allSubbrandsYaml.edges.forEach(n => {
              if (n.node.brand_id === brand.id) {
                n.node.types.forEach(m => {
                  m.sizes.forEach(l => {
                    most_min = l.min < most_min ? l.min : most_min
                    most_max = l.max > most_max ? l.max : most_max
                  })
                })
              }
            })
            return (
              <div className="column is-half is-vcentered">
                <Link to={`/${brand.id}.html`}>
                  <div className="card">
                    <div className="card-content" style={{paddingTop: `0.8rem`, paddingBottom: `0.8rem`, paddingLeft: `0.5rem`, paddingRight: `0.5rem`,}}>
                      <div className="media">
                        <div className="media-left" style={{margin: `0.0rem`}}>
                          <p className="is-6 has-text-weight-bold">{brand.description}</p>
                        </div>
                        <div className="media-content">
                        </div>
                        <div className="media-right" style={{margin: `0.0rem`}}>
                          <span className="icon">
													  <FaChevronRight />
													</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </>
   )
}

export default BrandsFilter

function includesSubbrand(target_types, target_brand_id, data){
  let ret = false
  for(let edge of data.allSubbrandsYaml.edges){
    if(edge.node.brand_id === target_brand_id){
      for(let type of edge.node.types){
        if(target_types.includes(type.type_id)){
          ret = true
          break
        }
      } 
    }
  }
  return ret
}