import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaChevronRight } from "react-icons/fa"

const SizesFilter = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allSizesYaml {
        edges {
          node {
            id
            description
          }
        }
      }
      allSubbrandsYaml {
        edges {
          node {
            brand_id
            subbrand_id
            description
            types {
              type_id 
              sizes {
                size_id
                min
                max
                unit
                num	
              }
            }
          }
        }
      }
    }
  `)
  // 指定のタイプ×サイズがサブブランドに含まれているサイズを抽出
  const filterSizesYaml = data.allSizesYaml.edges.filter(edge => {
    let target_size_id = edge.node.id
    return includesSubbrand(props.types, target_size_id, data)
  })

  return (
      <>
        <h2 className="title is-5" id="size" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>サイズから探す</h2>
        <div className="columns is-multiline is-gapless">
          {filterSizesYaml.map(edge => {
            const size = edge.node
            let most_min = 100
            let most_max = 0
            data.allSubbrandsYaml.edges.forEach(n => {
              n.node.types.forEach(m => {
                m.sizes.forEach(l => {
                  if(l.size_id === size.id) {
                    most_min = l.min < most_min ? l.min : most_min
                    most_max = l.max > most_max ? l.max : most_max
                  }
                })
              })
            })
            return (
              <div className="column is-half is-vcentered">
                <Link to={`/${size.id}_size.html`}>
                  <div className="card">
                    <div className="card-content" style={{paddingTop: `0.8rem`, paddingBottom: `0.8rem`, paddingLeft: `0.5rem`, paddingRight: `0.5rem`,}}>
                      <div className="media">
                        <div className="media-left" style={{margin: `0.0rem`}}>
                          <p className="is-6 has-text-weight-bold">{size.description}</p>
                        </div>
                        <div className="media-content">
                        </div>
                        <div className="media-right" style={{margin: `0.0rem`}}>
                          <span className="icon">
														<FaChevronRight />
													</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </>
   )
}

export default SizesFilter

function includesSubbrand(target_types, target_size_id, data){
  let ret = false
  for(let edge of data.allSubbrandsYaml.edges){
    for(let type of edge.node.types){
      if(target_types.includes(type.type_id)){
        for(let size of type.sizes){
          if(size.size_id === target_size_id){
            ret = true
            break
          }
        }
      }
    }
  }
  return ret
}